<template>
  <div class="commodity-list">
    <div class="title">{{ title }}</div>
    <van-row justify="space-around" :gutter="10">
      <van-col span="12" @click="JumpDetails(item.id)" v-for="item in products" :key="item.id">
        <van-image class="image" fit="cover" :src="`${$host}/${type}/` + item.defaultPic">
          <template v-slot:loading>
            <van-loading vertical type="spinner" size="20" />
          </template>
        </van-image>
        <span class="meta">{{ item.name }}</span>
        <p>
          <span>¥{{ item.price }}</span>
          <del>¥{{ item.mktPrice }}</del>
          <span>月销量{{ item.purchaseNums }}</span>
        </p>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: 'CommodityList',
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    JumpDetails(id) {
      if (this.type === 'commoditiess') return;
      this.$router.push({
        path: '/details',
        query: {
          id
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.commodity-list {
  padding: 10px;

  .title {
    padding: 18px 17px;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }

  .image {
    border: 1px solid #e6e6e6;
    border-radius: 3px;
  }

  span {
    font-size: 14px;
    text-align: left;
  }

  p {
    span {
      &:nth-of-type(1) {
        margin-right: 5px;
        color: red;
      }

      &:nth-of-type(2) {
        float: right;
        color: #999;
      }
    }
  }

  .meta {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
import request from '@/utils/request';

// 轮播图
export function banner(data) {
  return request({
    url: '/banner',
    method: 'post',
    data
  });
}

// 特辑
export function compaigns(data) {
  return request({
    url: '/compaigns',
    method: 'post',
    data
  });
}

// 销售排行榜
export function commoditiess(data) {
  return request({
    url: '/commoditiess',
    method: 'post',
    data
  });
}

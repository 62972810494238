<template>
  <div class="product-catagory">
    <div class="product-title">{{ title }}</div>
    <div class="product-list">
      <div v-for="item in category" :key="item.id" class="product-item" @click="navigateToCategory(item)">
        <van-image fit="cover" :src="`${$host}/category/` + item.iconPic">
          <template v-slot:loading>
            <van-loading vertical type="spinner" size="20" />
          </template>
        </van-image>
        <span class="product-item-text">{{ item.cateName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryList',
  props: {
    title: {
      type: String,
      default: ''
    },
    category: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    navigateToCategory(item) {
      this.$router.push({
        path: '/Category',
        query: {
          id: item.id
        }
      });
    }
  }
};
</script>

<style scoped lang='scss'>
.product-catagory {
  padding: 0 14px;

  .product-title {
    padding: 0 3px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }

  .product-list {
    display: flex;
    flex-wrap: wrap;
    padding: 18px 0;

    .product-item {
      width: 25%;
      // padding: 0 10px 7px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      .product-item-text {
        text-align: center;
        padding: 14px 0;
        width: 60px;
        font-size: 11px;
        font-weight: 700;
        line-height: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
}
</style>
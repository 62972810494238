<template>
  <div class="home-container">
    <van-image width="145" :src="logo">
      <template v-slot:loading>
        <van-loading vertical type="spinner" size="20" />
      </template>
    </van-image>

    <!-- 搜索栏 -->
    <van-search shape="round" v-model="hotsearch" placeholder="请输入搜索关键词" @click="search" />

    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" lazy-render>
      <van-swipe-item v-for="item in bannerImages" :key="item.id">
        <van-image fit="cover" :src="`${$host}/banners/` + item.defaultPic">
          <template v-slot:loading>
            <van-loading vertical type="spinner" size="20" />
          </template>
        </van-image>
      </van-swipe-item>
    </van-swipe>

    <div class="Special">
      <h1>特辑</h1>
      <van-grid :column-num="2" :icon-size="157">
        <van-grid-item v-for="item in special" :key="item.id" :icon="`${$host}/campaigns/` + item.coverPic" :text="item.title" />
      </van-grid>
    </div>

    <CommodityList type="commodities" title="新商品" :products="newProduct" />

    <h1>产品</h1>
    <CategoryList title="衣服·杂货" :category="clothesCategory" />
    <CategoryList title="生活杂货" :category="lifeCategory" />
    <CategoryList title="食品" :category="foodCategory" />

    <CommodityList type="commoditiess" title="销售排行榜" :products="commoditiess" />
  </div>
</template>

<script>
import CategoryList from '@/components/CategoryList';
import CommodityList from '@/components/CommodityList';
import { banner, compaigns, commoditiess } from '@/api/home';
import { category, commodities } from '@/api/common';

export default {
  name: 'Home',
  components: {
    CategoryList,
    CommodityList
  },
  data() {
    return {
      logo: require('../assets/logo.png'),
      hotsearch: '巧克力',
      bannerImages: [], //轮播图
      special: [], //特辑
      newProduct: [], //新商品
      clothesCategory: [], //衣服分类
      lifeCategory: [], //生活分类
      foodCategory: [], //食品分类
      commoditiess: [] //销售排行
    };
  },
  async created() {
    await Promise.all([this.getBanner(), this.getSpecial(), this.getNewProduct(), this.getClothes(), this.getLife(), this.getFood(), this.getSalesRank()]);
  },
  methods: {
    // 轮播图
    async getBanner() {
      try {
        const {
          data: { result }
        } = await banner({});
        this.bannerImages = result;
      } catch (error) {}
    },
    // 特辑
    async getSpecial() {
      try {
        const {
          data: { result }
        } = await compaigns({});
        this.special = result;
      } catch (error) {}
    },
    // 新商品
    async getNewProduct() {
      try {
        const {
          data: { result }
        } = await commodities({});
        this.newProduct = result;
      } catch (error) {}
    },
    // 衣服分类目录
    async getClothes() {
      try {
        const {
          data: { result }
        } = await category({ parentId: 100000018 });
        this.clothesCategory = result;
      } catch (error) {}
    },
    // 生活分类目录
    async getLife() {
      try {
        const {
          data: { result }
        } = await category({ parentId: 100000020 });
        this.lifeCategory = result;
      } catch (error) {}
    },
    // 食品分类目录
    async getFood() {
      try {
        const {
          data: { result }
        } = await category({ parentId: 100000022 });
        this.foodCategory = result;
      } catch (error) {}
    },
    // 销售排行榜
    async getSalesRank() {
      try {
        const {
          data: { result }
        } = await commoditiess({});
        this.commoditiess = result;
      } catch (error) {}
    },
    // 搜索
    search() {
      this.$router.push({
        path: '/search',
        query: {
          hotsearch: this.hotsearch
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.home-container {
  padding-bottom: 50px;

  h1 {
    font-size: 18px;
    text-align: center;
  }

  .Special {
    margin: 0;

    h2 {
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>